body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

:root {
  --caption-caption-1-medium-font-family: "Roboto", Helvetica;
  --caption-caption-1-medium-font-size: 16px;
  --caption-caption-1-medium-font-style: normal;
  --caption-caption-1-medium-font-weight: 500;
  --caption-caption-1-medium-letter-spacing: 0px;
  --caption-caption-1-medium-line-height: 24px;
  --caption-caption-1-reguler-font-family: "Roboto", Helvetica;
  --caption-caption-1-reguler-font-size: 16px;
  --caption-caption-1-reguler-font-style: normal;
  --caption-caption-1-reguler-font-weight: 400;
  --caption-caption-1-reguler-letter-spacing: 0px;
  --caption-caption-1-reguler-line-height: 24px;
  --caption-caption-2-medium-font-family: "Roboto", Helvetica;
  --caption-caption-2-medium-font-size: 14px;
  --caption-caption-2-medium-font-style: normal;
  --caption-caption-2-medium-font-weight: 500;
  --caption-caption-2-medium-letter-spacing: 0px;
  --caption-caption-2-medium-line-height: 22px;
  --caption-caption-2-reguler-font-family: "Roboto", Helvetica;
  --caption-caption-2-reguler-font-size: 14px;
  --caption-caption-2-reguler-font-style: normal;
  --caption-caption-2-reguler-font-weight: 400;
  --caption-caption-2-reguler-letter-spacing: 0px;
  --caption-caption-2-reguler-line-height: 22px;
  --colorsindigo: rgba(88, 86, 214, 1);
  --font-colorlight-subtitle: rgba(182, 182, 182, 1);
  --font-colorlight-title: rgba(236, 241, 240, 1);
  --font-colorpure-white: rgba(255, 255, 255, 1);
  --heading-heading-3-font-family: "Raleway", Helvetica;
  --heading-heading-3-font-size: 36px;
  --heading-heading-3-font-style: normal;
  --heading-heading-3-font-weight: 700;
  --heading-heading-3-letter-spacing: 0px;
  --heading-heading-3-line-height: 44px;
  --heading-heading-5-font-family: "Raleway", Helvetica;
  --heading-heading-5-font-size: 24px;
  --heading-heading-5-font-style: normal;
  --heading-heading-5-font-weight: 600;
  --heading-heading-5-letter-spacing: 0px;
  --heading-heading-5-line-height: 32px;
  --high-fidelity-colorcard-background: rgba(255, 255, 255, 0.02);
  --high-fidelity-colorsecondary-color: rgba(81, 18, 129, 1);
  --label-label-1-semibold-font-family: "Raleway", Helvetica;
  --label-label-1-semibold-font-size: 16px;
  --label-label-1-semibold-font-style: normal;
  --label-label-1-semibold-font-weight: 600;
  --label-label-1-semibold-letter-spacing: 0px;
  --label-label-1-semibold-line-height: 24px;
  --subtitle-subtitle-1-medium-font-family: "Roboto", Helvetica;
  --subtitle-subtitle-1-medium-font-size: 18px;
  --subtitle-subtitle-1-medium-font-style: normal;
  --subtitle-subtitle-1-medium-font-weight: 500;
  --subtitle-subtitle-1-medium-letter-spacing: 0px;
  --subtitle-subtitle-1-medium-line-height: 160%;
  --wireframe-colorgrey: rgba(198, 198, 198, 1);
  --wireframe-colorlight-grey: rgba(234, 234, 234, 1);
}