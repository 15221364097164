.market-table {
    background-color: transparent !important;
    box-shadow: none;
    border-radius: 4px;
    /* border: 1px solid #B6B6B6; */
    color: white !important;
  }
  
  .market-table-row .MuiTableCell-root {
    color: white !important;
    font-weight: bold;
  }
  .market-table-row   .MuiTableCell-body {
    color: white !important;
    font-weight: bold;
  }

  
  .table-row img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .table-button {
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .percent-change-positive {
    text-align: center;
    color: green !important;
  }
  
  .percent-change-negative {
    color: red !important;
  }
  